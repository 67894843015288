import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ArrayResult } from 'core/entities';
import { Session } from 'core/session.service';
import { DateTime } from 'luxon';
import { GameMode, MatchInvitationModel, MatchInvitationPlayerModel, MatchInvitationPlayerState, MatchInvitationState } from 'models';
import { interval } from 'rxjs';
import { AddNewMatchComponent, IAddMatchContext } from 'shared/add-new-match/add-new-match.component';
import {
    ISendInvitationModalContext,
    ISendInvitationModalResult,
    SendInvitationModalComponent,
} from 'shared/send-invitation-modal/send-invitation-modal.component';
import { AppState, sidebarActions } from 'store';
import { IComment, MatchInvitationAddCommentComponent } from './match-invitation-add-comment/match-invitation-add-comment.component';
import { NgIf, NgFor } from '@angular/common';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ChipComponent } from '../../chip/chip.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatchInvitationCommentComponent } from './match-invitation-comment/match-invitation-comment.component';
import { TranslateModule } from '@ngx-translate/core';
import { MomentPipe } from '../../pipes/moment.pipe';

@Component({
    selector: 'app-match-invitation-card',
    templateUrl: './match-invitation-card.component.html',
    styleUrls: ['./match-invitation-card.component.scss'],
    imports: [
        NgIf,
        MatProgressBar,
        NgFor,
        ChipComponent,
        SweetAlert2Module,
        MatchInvitationCommentComponent,
        MatchInvitationAddCommentComponent,
        TranslateModule,
        MomentPipe,
    ],
})
export class MatchInvitationCardComponent {
    #matchInvatation: MatchInvitationModel = null;

    public matchInvitationPlayerState = MatchInvitationPlayerState;
    public acceptCount = 0;
    public remainingTimeProgress = 0;
    public remainingTimeProgressColor = 'primary';

    @Input() set matchInvatation(value: MatchInvitationModel) {
        this.#matchInvatation = value;
        this.acceptCount = value.players.filter(
            (x) => x.state === MatchInvitationPlayerState.Accepted || x.state === MatchInvitationPlayerState.ReservePlayer,
        ).length;
        this.updateRemainingTimeProgress();
    }

    get matchInvatation() {
        return this.#matchInvatation;
    }

    get showJoin() {
        if (!this.#matchInvatation || this.#matchInvatation.idPlayer === this.idPlayer) {
            return false;
        }
        if (this.#matchInvatation.players.some((x) => x.state === MatchInvitationPlayerState.Accepted && x.idPlayer === this.idPlayer)) {
            return false;
        }

        return true;
    }

    get showRefuse() {
        if (!this.#matchInvatation || this.#matchInvatation.idPlayer === this.idPlayer) {
            return false;
        }
        if (this.#matchInvatation.players.some((x) => x.state === MatchInvitationPlayerState.Refused && x.idPlayer === this.idPlayer)) {
            return false;
        }
        return true;
    }

    get showReserve() {
        if (!this.#matchInvatation || this.#matchInvatation.idPlayer === this.idPlayer) {
            return false;
        }
        if (
            this.#matchInvatation.players.some((x) => x.state === MatchInvitationPlayerState.ReservePlayer && x.idPlayer === this.idPlayer)
        ) {
            return false;
        }
        return true;
    }

    get showStart() {
        if (this.#matchInvatation.gameMode === GameMode.OneAgainstOne) {
            return this.acceptCount >= 2;
        }
        if (this.#matchInvatation.gameMode === GameMode.TwoAgainstTwo) {
            return this.acceptCount >= 4;
        }
        return false;
    }

    public readonly idPlayer: number;

    constructor(
        private readonly http: HttpClient,
        private readonly session: Session,
        private readonly matDialog: MatDialog,
        private readonly store: Store<AppState>,
    ) {
        this.idPlayer = session.user.idPlayer;

        interval(1000)
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                this.updateRemainingTimeProgress();
            });
    }

    private updateRemainingTimeProgress() {
        if (this.matchInvatation) {
            const now = DateTime.now().toMillis();
            const start = DateTime.fromISO(this.matchInvatation.createdAt).toMillis();
            const end = DateTime.fromISO(this.matchInvatation.endsAt).toMillis();
            this.remainingTimeProgress = (1 - (now - start) / (end - start)) * 100;
            if (this.remainingTimeProgress < 0) {
                this.store.dispatch(sidebarActions.loadMatchInvitations({ lastModified: null }));
            }
            if (this.remainingTimeProgress < 10) {
                this.remainingTimeProgressColor = 'warn';
            }
        }
    }

    public changePlayerState(state: MatchInvitationPlayerState) {
        this.http
            .patch(`/api/match-invitations/${this.matchInvatation.id}/players/${this.session.user.idPlayer}`, {
                state,
            })
            .subscribe();
    }

    public startMatch() {
        this.http
            .patch<ArrayResult<MatchInvitationPlayerModel>>(`/api/match-invitations/${this.#matchInvatation.id}/start`, {})
            .subscribe((res) => {
                this.matDialog.open<AddNewMatchComponent, IAddMatchContext>(AddNewMatchComponent, {
                    position: { top: '20px' },
                    data: {
                        players: res.values.map((x) => x.idPlayer),
                        matchInvitationId: this.#matchInvatation.id,
                    },
                    disableClose: true,
                    width: '95%',
                    maxWidth: '800px',
                });
            });
    }

    public cancel() {
        this.store.dispatch(
            sidebarActions.changeMatchInvitationState({ id: this.matchInvatation.id, state: MatchInvitationState.Cancelled }),
        );
    }

    public getBooleanState(state: MatchInvitationPlayerState) {
        switch (state) {
            case MatchInvitationPlayerState.Accepted:
                return true;
            case MatchInvitationPlayerState.Refused:
                return false;
            default:
                return undefined;
        }
    }

    public openInvitationModal() {
        this.matDialog.open<SendInvitationModalComponent, ISendInvitationModalContext, ISendInvitationModalResult>(
            SendInvitationModalComponent,
            {
                panelClass: 'dialog-lg',
                data: { matchInvitation: this.#matchInvatation },
            },
        );
    }

    public showDivider(index: number) {
        if (this.matchInvatation.gameMode === GameMode.OneAgainstOne && this.acceptCount >= 2 && index === 1) {
            return true;
        }
        if (this.matchInvatation.gameMode === GameMode.TwoAgainstTwo && this.acceptCount >= 4 && index === 3) {
            return true;
        }
        return false;
    }

    public addComment(comment: IComment) {
        this.store.dispatch(
            sidebarActions.matchInvitationAddComment({ id: this.#matchInvatation.id, comment: comment.value, imageUrl: comment.imageUrl }),
        );
    }
}
