import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { EMPTY } from 'core/utils';
import { DateTime } from 'luxon';
import { GameMode, MatchPlayer, MatchSet, SetScoreGoalRole } from 'models';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { AppState, matchesActions } from 'store';
import { updateActiveMatchSet } from 'store/matches/matches.actions';
import { IMatch, IMatchTimers } from 'store/matches/matches.model';
import { selectActiveMatch, selectTimers } from 'store/matches/matches.reducers';
import { AddNewMatchComponent } from '../add-new-match.component';
import { isSetWon } from '../score-utils';
import { NgIf } from '@angular/common';
import { MatRipple } from '@angular/material/core';
import { FitTextDirective } from '../../directives/fit-text.directive';

interface IWakeLock {
    release(): Promise<void>;
}

const requestWakeLock = async () => {
    try {
        if ('wakeLock' in navigator) {
            const wakeLock = await (navigator as any).wakeLock.request('screen');
            return wakeLock;
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        return null;
    }
};

@Component({
    selector: 'app-match-course',
    templateUrl: './match-course.component.html',
    styleUrls: ['./match-course.component.scss', '../match-score/match-score.component.scss'],
    imports: [NgIf, MatRipple, FitTextDirective],
})
export class MatchCourseComponent implements OnInit, OnDestroy {
    public match: IMatch;
    #timers: IMatchTimers;
    private _wakeLock: IWakeLock = null;

    public setScoreGoalRole = SetScoreGoalRole;

    public set: MatchSet;
    private _unsubscribe = new Subject<void>();
    public containerHeight = 0;

    public heights: any = {};

    @HostListener('window:focus')
    onWindowFocus() {
        this._wakeLock?.release().catch(EMPTY);
        requestWakeLock()
            .then((res) => (this._wakeLock = res))
            .catch(EMPTY);
    }

    @HostListener('window:resize')
    onWindowResize() {
        this.adjustSize();
    }

    constructor(
        private readonly dialogRef: MatDialogRef<AddNewMatchComponent>,
        private readonly store: Store<AppState>,
    ) {}

    private adjustSize() {
        const height = window.innerHeight;
        const width = window.innerWidth;

        let dialogHeight: number;
        if (width < 768) {
            dialogHeight = Math.floor(height * 0.95);
            this.containerHeight = dialogHeight - 150;
        } else {
            dialogHeight = Math.floor(height * 0.8);
            this.containerHeight = dialogHeight - 120;
        }
        this.dialogRef.updateSize('95%', dialogHeight + 'px');

        this.heights = {
            inner: height,
            container: this.containerHeight,
            dialog: dialogHeight,
        };
    }

    ngOnInit(): void {
        requestWakeLock()
            .then((res) => (this._wakeLock = res))
            .catch(EMPTY);
        this.store
            .pipe(
                select(selectActiveMatch),
                withLatestFrom(this.store.pipe(select((x) => x.players.teamSettings))),
                takeUntil(this._unsubscribe),
            )
            .subscribe(([active, settings]) => {
                this.match = active;
                if (!active) {
                    return;
                }
                this.set = active.sets[active.sets.length - 1];

                if (!this.set) {
                    if (this.match.started) {
                        this.store.dispatch(matchesActions.setStarted({ started: false }));
                    }
                } else if (isSetWon(settings, this.set.teamA.score, this.set.teamB.score)) {
                    const teamAWinnigs = active.sets.filter((s) => s.teamA.score > s.teamB.score).length;
                    const teamBWinnigs = active.sets.filter((s) => s.teamA.score < s.teamB.score).length;

                    if (teamAWinnigs === settings.numberOfWinnedSetsToWin || teamBWinnigs === settings.numberOfWinnedSetsToWin) {
                        this.store.dispatch(matchesActions.saveActiveMatch());
                        this.dialogRef.close(true);
                    } else {
                        if (this.match.started) {
                            this.store.dispatch(matchesActions.setStarted({ started: false }));
                        }
                    }
                }
            });

        this.store
            .select(selectTimers)
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((timers) => (this.#timers = timers));
        this.adjustSize();
    }

    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
        if (this._wakeLock) {
            this._wakeLock
                .release()
                .then(() => (this._wakeLock = null))
                .catch(EMPTY);
        }
    }

    public addGoal(player: MatchPlayer, role: SetScoreGoalRole) {
        const lastGoal = this.set.goals.at(-1);
        let elapsed = this.#timers.fromLastGoal;
        if (lastGoal) {
            elapsed += lastGoal.elapsedSeconds;
        }
        this.set.elapsedSeconds = elapsed;
        let idPlayerDefense = this.set.teamB.defense.id;
        if (player.id === this.set.teamB.defense.id || player.id === this.set.teamB.offense.id) {
            idPlayerDefense = this.set.teamA.defense.id;
        }

        this.set.goals.push({
            createdAt: DateTime.now().toISO({ includeOffset: true }),
            idPlayer: player.id,
            elapsedSeconds: elapsed,
            role: role,
            idPlayerDefense: idPlayerDefense,
        });
        this.store.dispatch(updateActiveMatchSet({ set: this.set }));
        this.store.dispatch(matchesActions.clearTimers({ fromLastGoal: true }));
    }

    public getGoalCount(player: MatchPlayer, role: SetScoreGoalRole) {
        if (this.set?.goals?.length) {
            if (this.match.settings.gameMode === GameMode.TwoAgainstTwo) {
                return this.set.goals.filter((x) => x.idPlayer === player.id).length;
            } else {
                return this.set.goals.filter((x) => x.idPlayer === player.id && x.role === role).length;
            }
        } else {
            return 0;
        }
    }
}
