import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Session } from 'core/session.service';
import { Subject, takeUntil } from 'rxjs';
import { AddNewMatchComponent } from 'shared/add-new-match/add-new-match.component';
import { AppState, sidebarActions, userActions } from 'store';
import { loadMatchInvitations, toggleSidebar } from 'store/sidebar/sidebar.actions';
import { selectUnreadCount } from 'store/sidebar/sidebar.reducer';
import { NgIf } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-bottom-menu-bar',
    templateUrl: './bottom-menu-bar.component.html',
    styleUrls: ['./bottom-menu-bar.component.scss'],
    imports: [NgIf, RouterLink, RouterLinkActive, MatIcon, TranslateModule],
})
export class BottomMenuBarComponent implements OnInit, OnDestroy {
    public notificationCount = 0;
    #unsubscribe = new Subject<void>();

    public sidebarOpened = false;

    public menuOpened = false;

    @Output()
    public visibleChanged = new EventEmitter<boolean>();

    public showMenu = true;

    constructor(
        private readonly dialog: MatDialog,
        private readonly store: Store<AppState>,
        private readonly session: Session,
    ) {}

    ngOnInit(): void {
        this.visibleChanged.next(this.showMenu);
        this.store.pipe(select(selectUnreadCount), takeUntil(this.#unsubscribe)).subscribe((count) => {
            this.notificationCount = count;
            if (this.session.pushNotificatiosSupported && !this.session.isSubbscribedToPushNotifications) {
                this.notificationCount++;
            }
        });

        this.store
            .pipe(
                select((x) => x.sidebar.opened),
                takeUntil(this.#unsubscribe),
            )
            .subscribe((opened) => {
                this.sidebarOpened = opened;
            });

        this.store
            .pipe(
                select((x) => x.user.showMenu),
                takeUntil(this.#unsubscribe),
            )
            .subscribe((showMenu) => {
                this.menuOpened = showMenu;
            });

        this.dialog.afterOpened.pipe(takeUntil(this.#unsubscribe)).subscribe(() => {
            this.showMenu = false;
            this.visibleChanged.next(this.showMenu);
        });
        this.dialog.afterAllClosed.pipe(takeUntil(this.#unsubscribe)).subscribe(() => {
            this.showMenu = true;
            this.visibleChanged.next(this.showMenu);
        });
    }

    ngOnDestroy(): void {
        this.#unsubscribe.next();
        this.#unsubscribe.complete();
    }

    public toggleSidebar() {
        this.store.dispatch(loadMatchInvitations({ lastModified: null }));
        this.store.dispatch(toggleSidebar());
    }

    addMatchModal() {
        this.dialog.open(AddNewMatchComponent, {
            position: { top: '20px' },
            disableClose: true,
            width: '95%',
            maxWidth: '800px',
            maxHeight: '100vh',
        });
    }

    navigationClick() {
        if (this.sidebarOpened) {
            this.store.dispatch(sidebarActions.closeSidebar());
        }
    }

    menuClick() {
        this.store.dispatch(userActions.setMenuVisibility({ showMenu: !this.menuOpened }));
    }
}
