import { Component, Input } from '@angular/core';
import { GetMatchEloPointsModel } from 'models';
import { MatTooltip } from '@angular/material/tooltip';
import { NgTemplateOutlet, NgIf, DecimalPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-match-box-elo-points',
    templateUrl: './match-box-elo-points.component.html',
    styleUrls: ['./match-box-elo-points.component.scss'],
    imports: [MatTooltip, NgTemplateOutlet, NgIf, DecimalPipe, TranslateModule],
})
export class MatchBoxEloPointsComponent {
    @Input() elo1: GetMatchEloPointsModel;
    @Input() elo2: GetMatchEloPointsModel;
    @Input() expectedOutcome: number;
}
