import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { SavedMatchInfoModel } from 'models';
import { takeUntil, Subject } from 'rxjs';
import { AppState, matchesActions } from 'store';
import { selectSaved } from 'store/matches/matches.reducers';
import { FormsModule } from '@angular/forms';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { NgFor } from '@angular/common';
import { MatMiniFabButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MomentPipe } from '../../pipes/moment.pipe';

@Component({
    selector: 'app-load-saved-match',
    templateUrl: './load-saved-match.component.html',
    styleUrls: ['./load-saved-match.component.scss'],
    imports: [
        MatDialogTitle,
        FormsModule,
        CdkScrollable,
        MatDialogContent,
        MatSelectionList,
        NgFor,
        MatListOption,
        MatMiniFabButton,
        MatIcon,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        TranslateModule,
        MomentPipe,
    ],
})
export class LoadSavedMatchComponent implements OnInit, OnDestroy {
    public savedMatches: Array<SavedMatchInfoModel>;
    #unsubscribe = new Subject<void>();

    constructor(
        private readonly dialogRef: MatDialogRef<LoadSavedMatchComponent>,
        private readonly store: Store<AppState>,
    ) {}

    ngOnInit(): void {
        this.store.pipe(select(selectSaved), takeUntil(this.#unsubscribe)).subscribe((x) => (this.savedMatches = x));
        this.store.dispatch(matchesActions.loadSavedMatches());
    }

    ngOnDestroy(): void {
        this.#unsubscribe.next();
        this.#unsubscribe.complete();
    }

    public loadMatch(value: SavedMatchInfoModel) {
        this.store.dispatch(matchesActions.restoreSavedMatch({ id: value.id }));
        this.dialogRef.close();
    }
}
