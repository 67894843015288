import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, inject, OnInit, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BrowserStorageService, storageKeys } from 'core/browser-storage.service';
import { SignalRService } from 'core/signalr.service';
import { TeamSettings } from 'models';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AboutComponent } from 'shared/about/about.component';
import { InviteModalComponent } from 'shared/invite-modal/invite-modal.component';
import {
    ISendInvitationModalContext,
    ISendInvitationModalResult,
    SendInvitationModalComponent,
} from 'shared/send-invitation-modal/send-invitation-modal.component';
import { SwitchTeamModalComponent } from 'shared/switch-team-modal/switch-team-modal.component';
import { AppState, playersActions, sidebarActions, userActions } from 'store';
import { IPlayerLevel } from 'store/players/players.model';
import { selectSettings } from 'store/players/players.selectors';
import { loadMatchInvitations, toggleSidebar } from 'store/sidebar/sidebar.actions';
import { selectUnreadCount } from 'store/sidebar/sidebar.reducer';
import { Session } from './../../core/session.service';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatBadge } from '@angular/material/badge';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { MatDivider } from '@angular/material/divider';
import { BadgeNumberPipe } from '../pipes/badge-number.pipe';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
    imports: [
        RouterLink,
        MatButton,
        RouterLinkActive,
        MatIcon,
        MatBadge,
        NgIf,
        MatMenuTrigger,
        MatTooltip,
        MatIconButton,
        MatMenu,
        MatMenuItem,
        NgFor,
        MatDivider,
        AsyncPipe,
        TranslateModule,
        BadgeNumberPipe,
    ],
})
export class TopbarComponent implements OnInit {
    public activateRoute: string;
    public showMenu = false;
    public levelInfo$: Observable<IPlayerLevel>;
    public levelTooltip = '';
    public teamSettings$: Observable<TeamSettings>;
    public notificationCount: number;
    public currentPageTitle: string = null;

    #destroyRef = inject(DestroyRef);
    #settings: Signal<TeamSettings>;

    constructor(
        public readonly session: Session,
        private readonly store: Store<AppState>,
        private readonly signalR: SignalRService,
        private readonly translate: TranslateService,
        private readonly http: HttpClient,
        private readonly matDialog: MatDialog,
        private readonly storage: BrowserStorageService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.teamSettings$ = this.store.pipe(select((x) => x.players.teamSettings));
        this.levelInfo$ = this.store.pipe(select((x) => x.players.levelInfo));
        this.store
            .pipe(
                select((x) => x.season.active),
                takeUntilDestroyed(this.#destroyRef),
            )
            .subscribe(() => {
                this.store.dispatch(playersActions.loadPlayerLevel());
            });
        this.signalR.newMatch.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
            this.store.dispatch(playersActions.loadPlayerLevel());
        });

        this.store.pipe(select(selectUnreadCount), takeUntilDestroyed(this.#destroyRef)).subscribe((count) => {
            this.notificationCount = count;
            if (this.session.pushNotificatiosSupported && !this.session.isSubbscribedToPushNotifications) {
                this.notificationCount++;
            }
        });

        this.#settings = this.store.selectSignal(selectSettings);
        this.store
            .pipe(
                select((x) => x.user.showMenu),
                takeUntilDestroyed(this.#destroyRef),
            )
            .subscribe((showMenu) => {
                this.showMenu = showMenu;
            });

        this.store.dispatch(sidebarActions.getNotificationCount());

        this.currentPageTitle = this.route.root.firstChild.snapshot.data['pageTitle'] || null;
        this.router.events
            .pipe(
                takeUntilDestroyed(this.#destroyRef),
                filter((x) => x instanceof NavigationEnd),
            )
            .subscribe(() => {
                this.currentPageTitle = this.route.root.firstChild.snapshot.data['pageTitle'] || null;
            });
    }

    public logout() {
        this.session.logout();
    }

    public switchTeam(id: number) {
        this.session.switchTeam(id);
    }

    public changeLanguage(lang: string) {
        this.storage.local.setItem(storageKeys.lang, lang);
        this.translate.use(lang);
        this.http.patch('/api/players/set-lang', { language: lang.substr(0, 2) }).subscribe();
    }

    public openAbout() {
        this.matDialog.open<AboutComponent>(AboutComponent, { maxWidth: '850px' });
    }

    public openSendMatchInvitationModal() {
        this.matDialog.open<SendInvitationModalComponent, ISendInvitationModalContext, ISendInvitationModalResult>(
            SendInvitationModalComponent,
            {
                panelClass: 'dialog-lg',
                data: {
                    gameMode: this.#settings().gameMode,
                },
            },
        );
    }

    public openInviteModal() {
        this.matDialog
            .open<InviteModalComponent>(InviteModalComponent, { autoFocus: false })
            .afterClosed()
            .subscribe(() => {
                this.store.dispatch(playersActions.loadPlayers({ force: true }));
            });
    }

    public toggleSidebar() {
        this.store.dispatch(loadMatchInvitations({ lastModified: null }));
        this.store.dispatch(toggleSidebar());
    }

    public openSwitchTeamModal() {
        this.matDialog.open<SwitchTeamModalComponent>(SwitchTeamModalComponent, { panelClass: 'dialog-md' });
    }

    public hideMenu() {
        this.store.dispatch(userActions.setMenuVisibility({ showMenu: false }));
    }

    public createNewTeam() {
        this.http.delete('/api/teams/clear-selected').subscribe(() => {
            window.location.href = '/account/create/2';
        });
    }
}
