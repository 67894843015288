import { Component, DestroyRef, OnDestroy, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { SwalComponent, SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { EMPTY } from 'core/utils';
import { DateTime } from 'luxon';
import { GameMode, MatchExpectedOutcomeModel, MatchSet, MatchSetTeam, MatchTeam, TeamSettings } from 'models';
import { withLatestFrom } from 'rxjs';
import { AppState, matchesActions } from 'store';
import { switchTeamMembers } from 'store/matches/matches.actions';
import { IMatch } from 'store/matches/matches.model';
import { selectActiveMatch } from 'store/matches/matches.reducers';
import { selectSettings } from 'store/players/players.selectors';
import { AddNewMatchComponent } from '../add-new-match.component';
import { isSetWon } from '../score-utils';
import { NgIf, DecimalPipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMiniFabButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-start-match-set',
    templateUrl: './start-match-set.component.html',
    styleUrls: ['./start-match-set.component.scss'],
    imports: [NgIf, MatTooltip, MatMiniFabButton, MatIcon, MatButton, SweetAlert2Module, DecimalPipe, TranslateModule],
})
export class StartMatchSetComponent implements OnInit, OnDestroy {
    match: IMatch;

    remainigSeconds = 10;
    cancelInterval = true;

    #destroyRef = inject(DestroyRef);

    public gameMode = GameMode;

    public teamA: MatchSetTeam = null;
    public teamB: MatchSetTeam = null;

    public settings: TeamSettings;

    #updateActiveSet = false;
    public expectedResult: MatchExpectedOutcomeModel = null;
    public canSwitchBetweenTeams = false;
    public canASwitchRoles = false;
    public canBSwitchRoles = false;

    constructor(
        private readonly store: Store<AppState>,
        private readonly dialogRef: MatDialogRef<AddNewMatchComponent>,
    ) {}

    ngOnInit(): void {
        this.dialogRef.updateSize('95%', null);

        this.store
            .select(selectSettings)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((settings) => {
                this.settings = settings;
            });

        this.store
            .pipe(
                select(selectActiveMatch),
                withLatestFrom(this.store.pipe(select((x) => x.players.teamSettings))),
                takeUntilDestroyed(this.#destroyRef),
            )
            .subscribe(([match, settings]) => {
                this.match = match;
                this.expectedResult = match?.expectedOutcome;

                if (!match) {
                    return;
                }
                this.canSwitchBetweenTeams = match.sets.length === 0 && match.settings.gameMode === GameMode.TwoAgainstTwo;
                const lastSet = match.sets.at(-1);

                this.canASwitchRoles = this.evaluateCanSwitchRoles(match, settings, MatchTeam.A);
                this.canBSwitchRoles = this.evaluateCanSwitchRoles(match, settings, MatchTeam.B);

                if (!lastSet) {
                    this.teamA = { defense: match.teamA[0], offense: match.teamA[1], score: 0 };
                    this.teamB = { defense: match.teamB[0], offense: match.teamB[1], score: 0 };
                } else if (!isSetWon(settings, lastSet.teamA.score, lastSet.teamB.score)) {
                    this.#updateActiveSet = true;
                    this.teamA = { ...lastSet.teamA };
                    this.teamB = { ...lastSet.teamB };
                } else if (settings.autoSwitchPlayersOnNextSet) {
                    const teamAWinnigs = match.sets.filter((s) => s.teamA.score > s.teamB.score).length;
                    const teamBWinnigs = match.sets.filter((s) => s.teamA.score < s.teamB.score).length;

                    if (teamAWinnigs > 0 && teamBWinnigs > 0) {
                        const lastAWon = match.sets.filter((s) => s.teamA.score > s.teamB.score).at(-1);
                        this.teamA = {
                            defense: lastAWon.teamA.defense,
                            offense: lastAWon.teamA.offense,
                            score: 0,
                        };
                    } else {
                        this.teamA = {
                            defense: lastSet.teamA.offense,
                            offense: lastSet.teamA.defense,
                            score: 0,
                        };
                    }

                    if (teamAWinnigs > 0 && teamBWinnigs > 0) {
                        const lastBWon = match.sets.filter((s) => s.teamA.score < s.teamB.score).at(-1);
                        this.teamB = {
                            defense: lastBWon.teamB.defense,
                            offense: lastBWon.teamB.offense,
                            score: 0,
                        };
                    } else {
                        this.teamB = {
                            defense: lastSet.teamB.offense,
                            offense: lastSet.teamB.defense,
                            score: 0,
                        };
                    }
                } else {
                    this.teamA = { ...lastSet.teamA, score: 0 };
                    this.teamB = { ...lastSet.teamB, score: 0 };
                }
                this.updateExpectedResult();
            });
    }

    private evaluateCanSwitchRoles(match: IMatch, settings: TeamSettings, team: MatchTeam) {
        if (match.settings.gameMode === GameMode.OneAgainstOne) {
            return false;
        }
        const lastSet = match.sets.at(-1);

        if (!lastSet || lastSet.goals.length === 0 || isSetWon(settings, lastSet.teamA.score, lastSet.teamB.score)) {
            return true;
        }
        if (!settings.allowPositionSwitchingDuringSet) {
            return false;
        }
        const switchCount = (team === MatchTeam.A ? lastSet.teamA.positionSwitchCount : lastSet.teamB.positionSwitchCount) || 0;
        if (settings.maximumPositionSwitchingDuringSet !== null && switchCount >= settings.maximumPositionSwitchingDuringSet) {
            return false;
        }

        return true;
    }

    ngOnDestroy() {
        this.cancelInterval = true;
    }

    switchRole(team: MatchSetTeam) {
        const temp = team.defense;
        team.defense = team.offense;
        team.offense = temp;
        this.cancelInterval = true;
    }

    switchTeam(position: 1 | 2) {
        if (!this.canSwitchBetweenTeams) {
            return;
        }

        if (position === 1) {
            this.store.dispatch(
                switchTeamMembers({ teamA: [this.teamA.defense, this.teamB.defense], teamB: [this.teamA.offense, this.teamB.offense] }),
            );
        } else if (position === 2) {
            this.store.dispatch(
                switchTeamMembers({ teamA: [this.teamB.offense, this.teamA.offense], teamB: [this.teamB.defense, this.teamA.defense] }),
            );
        }
    }

    continue(switchConfirmSwal: SwalComponent) {
        if (this.#updateActiveSet) {
            const lastSet = this.match.sets.at(-1);
            let positionChanged = false;
            if (lastSet.goals.length) {
                if (lastSet.teamA.defense.id !== this.teamA.defense.id) {
                    this.teamA.positionSwitchCount = (lastSet.teamA.positionSwitchCount || 0) + 1;
                    positionChanged = true;
                }
                if (lastSet.teamB.defense.id !== this.teamB.defense.id) {
                    this.teamB.positionSwitchCount = (lastSet.teamB.positionSwitchCount || 0) + 1;
                    positionChanged = true;
                }
            }

            let confirmed = Promise.resolve(true);

            if (lastSet.goals.length !== 0 && positionChanged && this.settings.maximumPositionSwitchingDuringSet !== null) {
                confirmed = switchConfirmSwal.fire().then((result) => result.isConfirmed);
            }

            confirmed
                .then((result) => {
                    if (result) {
                        const update: MatchSet = { ...lastSet, teamA: this.teamA, teamB: this.teamB };
                        this.store.dispatch(
                            matchesActions.updateActiveMatchSet({
                                set: update,
                            }),
                        );
                    }
                    this.store.dispatch(matchesActions.setStarted({ started: true }));
                })
                .catch(EMPTY);
        } else {
            this.store.dispatch(matchesActions.clearTimers({ fromLastGoal: true, fromSetStart: true }));
            this.store.dispatch(
                matchesActions.createActiveMatchSet({
                    set: {
                        teamA: this.teamA,
                        teamB: this.teamB,
                        goals: [],
                        createdAt: DateTime.now().toISO({ includeOffset: true }),
                        elapsedSeconds: 0,
                    },
                }),
            );
            this.store.dispatch(matchesActions.setStarted({ started: true }));
        }
    }

    endMatch() {
        this.store.dispatch(matchesActions.saveActiveMatch());
        this.dialogRef.close(true);
    }

    private updateExpectedResult() {
        if (this.expectedResult) {
            if (
                this.expectedResult.idPlayer1 === this.teamA.defense.id &&
                this.expectedResult.idPlayer2 === this.teamA.offense.id &&
                this.expectedResult.idPlayer3 === this.teamB.defense.id &&
                this.expectedResult.idPlayer4 === this.teamB.offense.id
            ) {
                return;
            }
        }
        this.store.dispatch(
            matchesActions.setExpectedOutcome({
                players: [this.teamA.defense.id, this.teamA.offense.id, this.teamB.defense.id, this.teamB.offense.id],
            }),
        );
    }
}
