import { Component, input, output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmojiSearch, PickerComponent } from '@ctrl/ngx-emoji-mart';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { GiphModalComponent, IGiph } from 'shared/giph-modal/giph-modal.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

export interface IComment {
    value: string;
    imageUrl: string;
    attachments: Array<string>;
}

@Component({
    selector: 'app-match-invitation-add-comment',
    templateUrl: './match-invitation-add-comment.component.html',
    styleUrl: './match-invitation-add-comment.component.scss',
    imports: [NgIf, FormsModule, MatIcon, MatTooltip, PickerComponent, TranslateModule],
})
export class MatchInvitationAddCommentComponent {
    #knownEmojies: Array<string>;
    public comment = '';
    public showEmoPicker = false;

    public imageUrl: string = null;

    public placeholder = input<string>();
    public supportsAttachments = input<boolean>(false);
    public sentComment = output<IComment>();

    constructor(
        private readonly emoSearch: EmojiSearch,
        private readonly dialog: MatDialog,
    ) {
        this.#knownEmojies = Object.keys(emoSearch.emoticonsList);
    }

    public addComment() {
        if (!this.comment && !this.imageUrl) {
            return;
        }
        this.sentComment.emit({ value: this.comment, imageUrl: this.imageUrl, attachments: [] });
        this.comment = '';
    }

    public onTextChange(value: string) {
        if (!value) {
            return;
        }
        const emo = this.#knownEmojies.find((x) => value.endsWith(x));
        if (emo) {
            const emoName = this.emoSearch.emoticonsList[emo];
            value = value.replace(emo, this.emoSearch.emojisList[emoName].native);
        }
        this.comment = value;
    }
    public onEmojiSelect(event: EmojiEvent) {
        this.comment += event.emoji.native;
    }

    public addGif() {
        this.dialog
            .open<GiphModalComponent, any, IGiph>(GiphModalComponent, { panelClass: 'dialog-md' })
            .afterClosed()
            .subscribe((res) => {
                this.imageUrl = res?.fixedHeight || null;
            });
    }
}
