import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
} from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FormGroup, TypedFormBuilder } from 'core/forms';
import { Session } from 'core/session.service';
import { DateTime } from 'luxon';
import { GameMode, MatchInvitationCreateModel, MatchInvitationModel, MatchInvitationUpdateModel, TeamMemberRole } from 'models';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppState, playersSelectors, sidebarActions } from 'store';
import { IPlayer } from 'store/players/players.model';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { ChipComponent } from '../chip/chip.component';
import { NgFor } from '@angular/common';
import { MatButton } from '@angular/material/button';

interface IPlayerExt extends IPlayer {
    selected: boolean;
    disabled: boolean;
}

export interface ISendInvitationModalContext {
    matchInvitation?: MatchInvitationModel;
    gameMode?: GameMode;
}
export interface ISendInvitationModalResult {
    message: string;
    players: Array<number>;
}

@Component({
    selector: 'app-send-invitation',
    templateUrl: './send-invitation-modal.component.html',
    styleUrls: ['./send-invitation-modal.component.scss'],
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        MatFormField,
        MatLabel,
        MatInput,
        FormsModule,
        ReactiveFormsModule,
        MatSlider,
        MatSliderThumb,
        ChipComponent,
        NgFor,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        TranslateModule,
    ],
})
export class SendInvitationModalComponent implements OnInit, OnDestroy {
    #unsubscribe = new Subject<void>();

    public form: FormGroup<MatchInvitationCreateModel>;
    public players: Array<IPlayerExt>;

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly context: ISendInvitationModalContext,
        private readonly dialogRef: MatDialogRef<SendInvitationModalComponent, ISendInvitationModalResult>,
        private readonly translate: TranslateService,
        private readonly session: Session,
        private readonly store: Store<AppState>,
        private readonly http: HttpClient,
        formBuilder: TypedFormBuilder,
    ) {
        this.form = formBuilder.group<MatchInvitationCreateModel>({
            message: [this.translate.instant('ADD_MATCH.DEFAULT_MESSAGE', { name: this.session.user.displayName }), Validators.required],
            maxOpenDuration: [60, [Validators.min(1), Validators.max(120)]],
        });

        if (context.matchInvitation) {
            const createdAt = DateTime.fromISO(context.matchInvitation.createdAt);
            const endsAt = DateTime.fromISO(context.matchInvitation.endsAt);
            const maxOpenDuration = Math.floor(endsAt.diff(createdAt, 'minutes').minutes);
            this.form.get('maxOpenDuration').setValue(maxOpenDuration);
        }
    }

    ngOnInit() {
        this.store
            .pipe(
                select(playersSelectors.selectPlayeblePlayers),
                map((res) =>
                    res.filter(
                        (x) =>
                            x.id !== this.session.user.idPlayer &&
                            (x.teamMemberRole === TeamMemberRole.Player || x.teamMemberRole === TeamMemberRole.Guest),
                    ),
                ),
                takeUntil(this.#unsubscribe),
            )
            .subscribe((players) => {
                this.players = players
                    .filter((x) => x.user.email)
                    .map((x) => {
                        const selected = this.context.matchInvitation
                            ? this.context.matchInvitation.players.some((p) => p.idPlayer === x.id)
                            : false;
                        return {
                            ...x,
                            selected: selected,
                            disabled: selected,
                        };
                    });
            });
    }

    ngOnDestroy(): void {
        this.#unsubscribe.next();
        this.#unsubscribe.complete();
    }

    public allSelected() {
        return this.players.every((x) => x.selected);
    }

    public selectAllChanged() {
        if (this.allSelected()) {
            this.players.forEach((x) => (x.selected = false));
        } else {
            this.players.forEach((x) => (x.selected = true));
        }
    }

    public saveDisabled() {
        if (this.context.gameMode === GameMode.OneAgainstOne) {
            return this.players.filter((x) => x.selected).length < 1;
        } else {
            return this.players.filter((x) => x.selected).length < 3;
        }
    }

    onSave() {
        if (this.saveDisabled() || this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        const selectedPlayers = this.players.filter((x) => x.selected).map((x) => x.id);

        if (this.context.matchInvitation) {
            const model: MatchInvitationUpdateModel = {
                ...this.form.value,
                state: this.context.matchInvitation.state,
                newPlayers: selectedPlayers.filter((x) => !this.context.matchInvitation.players.some((p) => p.idPlayer === x)),
            };
            this.http.patch<never>(`/api/match-invitations/${this.context.matchInvitation.id}`, model).subscribe(() => {
                this.closeDialog();
            });
        } else {
            const model: MatchInvitationCreateModel = {
                ...this.form.value,
                players: selectedPlayers,
                gameMode: this.context.gameMode || GameMode.TwoAgainstTwo,
            };

            if (this.form.get('message').untouched) {
                model.message = null;
            }

            this.http.post<MatchInvitationModel>('/api/match-invitations', model).subscribe(() => {
                this.closeDialog();
            });
        }
    }

    private closeDialog() {
        this.store.dispatch(sidebarActions.getNotificationCount());
        this.store.dispatch(sidebarActions.openSidebar());
        this.dialogRef.close();
    }
}
