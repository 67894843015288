import { Component, Input } from '@angular/core';
import { BehaviorSubject, Observable, delayWhen, interval, map, of, pairwise } from 'rxjs';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-loading-panel',
    templateUrl: './loading-panel.component.html',
    styleUrls: ['./loading-panel.component.scss'],
    imports: [NgIf, MatProgressSpinner, AsyncPipe],
})
export class LoadingPanelComponent {
    @Input() set loading(value: boolean) {
        this.#loadingSubject.next(value);
    }
    @Input() text = '';

    #loadingSubject = new BehaviorSubject<boolean>(false);

    isLoading$: Observable<boolean>;

    constructor() {
        this.isLoading$ = this.#loadingSubject.pipe(
            pairwise(),
            delayWhen<[boolean, boolean]>(([prev, next]) => (prev ? interval(300) : of(next))),
            map((x) => x[1]),
        );
    }
}
