import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY } from 'core/utils';
import { BellNotificationModel } from 'models';
import { AppState, sidebarActions } from 'store';
import { NgIf, DatePipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MomentPipe } from '../../pipes/moment.pipe';

@Component({
    selector: 'app-notification-card',
    templateUrl: './notification-card.component.html',
    styleUrls: ['./notification-card.component.scss'],
    imports: [NgIf, MatTooltip, MatIcon, DatePipe, TranslateModule, MomentPipe],
})
export class NotificationCardComponent {
    @Input() notification: BellNotificationModel;

    constructor(
        private readonly router: Router,
        private readonly store: Store<AppState>,
    ) {}

    public onNotificationClick() {
        if (this.notification?.url) {
            this.router
                .navigateByUrl(this.notification.url)
                .then(() => {
                    this.store.dispatch(sidebarActions.closeSidebar());
                })
                .catch(EMPTY);
        }
    }
}
