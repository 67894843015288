import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, DestroyRef, ElementRef, Input, OnInit, ViewChild, inject, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { EmojiSearch, PickerComponent } from '@ctrl/ngx-emoji-mart';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Store } from '@ngrx/store';
import { BlobMetadataModel, CreateMatchCommentModel, GetMatchCommentModel, UpdateMatchCommentModel } from 'models';
import { GiphModalComponent, IGiph } from 'shared/giph-modal/giph-modal.component';
import { AppState, matchesActions } from 'store';
import {
    UploadingAttachment,
    UploadingCommentAttachmentComponent,
} from '../uploading-comment-attachment/uploading-comment-attachment.component';
import { NgIf, NgFor } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { CommentAttachmentComponent } from '../comment-attachment/comment-attachment.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-upsert-comment',
    templateUrl: './upsert-comment.component.html',
    styleUrls: ['./upsert-comment.component.scss'],
    imports: [
        NgIf,
        MatInput,
        FormsModule,
        MatIcon,
        MatTooltip,
        PickerComponent,
        NgFor,
        CommentAttachmentComponent,
        UploadingCommentAttachmentComponent,
        TranslateModule,
    ],
})
export class UpsertCommentComponent implements OnInit, AfterViewInit {
    public idMatch = input.required<number>();
    public idParentComment = input<number>(null);
    public comment = input<GetMatchCommentModel>(null);

    public text = '';
    public imageUrl: string = null;
    public imagePreview: string = null;

    public attachments: Array<BlobMetadataModel> = [];
    public uploadingAttachments: Array<UploadingAttachment> = [];

    @Input() focusOnInit = false;

    private _knownEmojies: Array<string>;
    #destroyRef = inject(DestroyRef);
    public showEmoPicker = false;

    #key = '';

    @ViewChild('textArea') textArea: ElementRef<HTMLTextAreaElement>;

    constructor(
        private readonly dialog: MatDialog,
        private readonly emoSearch: EmojiSearch,
        private readonly http: HttpClient,
        private readonly store: Store<AppState>,
    ) {
        this._knownEmojies = Object.keys(emoSearch.emoticonsList);
    }

    ngOnInit(): void {
        this.#key = `${this.comment()?.id || null}_${this.idMatch()}_${this.idParentComment()}`;
        this.store
            .select((state) => state.matches.editingComments[this.#key])
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((comment) => {
                this.text = comment?.text || '';
                this.imageUrl = comment?.imageUrl || null;
                this.attachments = comment?.attachments || [];
            });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.focusOnInit) {
                this.textArea.nativeElement.focus();
            }
        });
    }

    public addGif() {
        this.dialog
            .open<GiphModalComponent, any, IGiph>(GiphModalComponent, { panelClass: 'dialog-md' })
            .afterClosed()
            .subscribe((res) => {
                this.imageUrl = res?.fixedHeight || null;
                this.imagePreview = res?.still480w || null;
            });
    }

    public onTextChange(value: string) {
        if (!value) {
            return;
        }
        const emo = this._knownEmojies.find((x) => value.endsWith(x));
        if (emo) {
            const emoName = this.emoSearch.emoticonsList[emo];
            value = value.replace(emo, this.emoSearch.emojisList[emoName].native);
        } else if (value.endsWith('(y)')) {
            value = value.replace('(y)', this.emoSearch.emojisList['+1'].native);
        }
        this.text = value;

        this.store.dispatch(
            matchesActions.updateMatchComment({
                key: this.#key,
                comment: {
                    imagePreview: this.imagePreview,
                    imageUrl: this.imageUrl,
                    text: this.text,
                    attachments: this.attachments,
                },
                skipIfExists: false,
            }),
        );
    }

    public sentComment() {
        if ((!this.text && !this.imageUrl) || this.uploadingAttachments.length) {
            return;
        }

        if (this.comment()) {
            const existingAttachments = this.comment().attachments.map((x) => x.id);
            const comment: UpdateMatchCommentModel = {
                text: this.text,
                imageUrl: this.imageUrl,
                attachments: this.attachments.filter((x) => !existingAttachments.includes(x.id)).map((x) => x.id),
            };

            this.http
                .patch<unknown>(`/api/matches/${this.idMatch()}/comments/${this.comment().id}`, comment)
                .subscribe(() => this.store.dispatch(matchesActions.clearMatchComment({ key: this.#key })));
        } else {
            const comment: CreateMatchCommentModel = {
                createdAt: new Date().toISOString(),
                idParent: this.idParentComment(),
                imagePreview: this.imagePreview,
                imageUrl: this.imageUrl,
                text: this.text,
                attachments: this.attachments.map((x) => x.id),
            };

            this.http.post<unknown>(`/api/matches/${this.idMatch()}/comments`, comment).subscribe(() => {
                this.store.dispatch(matchesActions.clearMatchComment({ key: this.#key }));
            });
        }
    }

    public onEmojiSelect(event: EmojiEvent) {
        this.text += event.emoji.native;
    }

    public onAttachmentFileChange(event: Event) {
        const files = (event.target as HTMLInputElement).files;
        this.uploadFiles(Array.from(files));
    }

    public onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.uploadFiles(Array.from(event.dataTransfer.files));
    }

    public onPaste(event: ClipboardEvent) {
        const items = event.clipboardData.items;
        if (items.length) {
            const files = Array.from(items)
                .filter((x) => x.kind === 'file')
                .map((x) => x.getAsFile());
            this.uploadFiles(files);
        }
    }

    private uploadFiles(files: Array<File>) {
        files
            .filter((file) => file.type.startsWith('image') || file.type.startsWith('video'))
            .forEach((file) => {
                const formData = new FormData();
                formData.append(file.name, file, file.name);

                const uploadingFile: UploadingAttachment = {
                    isImage: file.type.startsWith('image'),
                    name: file.name,
                    progress: 0,
                };
                this.uploadingAttachments.push(uploadingFile);
                const headers = new HttpHeaders().set('ngsw-bypass', '');
                this.http
                    .post('/api/upload', formData, { observe: 'events', reportProgress: true, headers })
                    .pipe(takeUntilDestroyed(this.#destroyRef))
                    .subscribe((event) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            uploadingFile.progress = Math.round((100 * event.loaded) / event.total);
                        } else if (event.type === HttpEventType.Response) {
                            const body = event.body as Array<BlobMetadataModel>;
                            this.attachments.push(...body);
                            this.uploadingAttachments = this.uploadingAttachments.filter((x) => x !== uploadingFile);
                        }
                    });
            });
    }
}
