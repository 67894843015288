import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { BrowserStorageService, storageKeys } from 'core/browser-storage.service';
import { Session } from 'core/session.service';
import { Subject, filter, takeUntil } from 'rxjs';
import { SubscribeToPushNotificationModalComponent } from 'shared/subscribe-to-push-notification-modal/subscribe-to-push-notification-modal.component';
import { AppState } from 'store';
import { selectCurrentUser } from 'store/user/user.selectors';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-allow-push-notifications',
    templateUrl: './allow-push-notifications.component.html',
    styleUrls: ['./allow-push-notifications.component.scss'],
    imports: [NgIf, TranslateModule],
})
export class AllowPushNotificationsComponent implements OnInit, OnDestroy {
    public showAlert = false;
    #unsubscribe = new Subject<void>();

    constructor(
        private readonly session: Session,
        private readonly dialog: MatDialog,
        private readonly storage: BrowserStorageService,
        private readonly store: Store<AppState>,
    ) {}

    ngOnInit(): void {
        this.store
            .pipe(
                select(selectCurrentUser),
                takeUntil(this.#unsubscribe),
                filter((x) => x !== null),
            )
            .subscribe((user) => {
                if (this.storage.local.getItem(storageKeys.dontShowPushAlert) === 'true') {
                    this.showAlert = false;
                } else {
                    const browserId = this.storage.local.getItem(storageKeys.browserId);

                    this.showAlert =
                        this.session.pushNotificatiosSupported &&
                        user.notificationSubscriptions.find((x) => x.browserId === browserId) === undefined;
                }
            });
    }

    ngOnDestroy(): void {
        this.#unsubscribe.next();
        this.#unsubscribe.complete();
    }

    subscribeToPushNotifications() {
        this.dialog.open(SubscribeToPushNotificationModalComponent);
    }

    public dismiss(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.storage.local.setItem(storageKeys.dontShowPushAlert, 'true');
        this.showAlert = false;
    }
}
