<div class="text-center">
    <div class="swal2-icon swal2-error">
        <span class="swal2-x-mark"><span class="swal2-x-mark-line-left"></span><span class="swal2-x-mark-line-right"></span></span>
    </div>
    <h2 class="text-danger mt-2">
        {{ 'COMMON.API_ERROR_MESSAGE_TITLE' | translate }}
    </h2>
    <h5 class="my-3">
        {{ 'COMMON.API_ERROR_MESSAGE' | translate }}
    </h5>
</div>
<mat-dialog-content class="text-center mt-3"> <b>Trace id:</b> {{ error.traceId }} </mat-dialog-content>
<div mat-dialog-actions class="float-right">
    <button mat-button mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
</div>
