import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'badgeNumber' })
export class BadgeNumberPipe implements PipeTransform {
    transform(value: number): string | number {
        if (value < 1000) {
            return value;
        }
        const k = Math.floor(value / 1000);

        return `${k}k`;
    }
}
