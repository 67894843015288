import { Component, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

export interface UploadingAttachment {
    isImage: boolean;
    name: string;
    progress: number;
}

@Component({
    selector: 'app-uploading-comment-attachment',
    templateUrl: './uploading-comment-attachment.component.html',
    styleUrl: './uploading-comment-attachment.component.scss',
    imports: [MatIcon, MatProgressSpinner],
})
export class UploadingCommentAttachmentComponent {
    public readonly attachment = input.required<UploadingAttachment>();
}
