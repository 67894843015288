import { Component, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { MatchSet, SetScoreGoalRole } from 'models';
import { IMatch } from 'store/matches/matches.model';
import { MatTable, MatColumnDef, MatCellDef, MatCell, MatRowDef, MatRow } from '@angular/material/table';
import { NgIf } from '@angular/common';

interface IGoal {
    playerA?: string;
    playerB?: string;
    score?: string;
    time?: string;
    role: SetScoreGoalRole;
}

@Component({
    selector: 'app-ongoing-match-box-goals',
    templateUrl: './ongoing-match-box-goals.component.html',
    styleUrls: ['./ongoing-match-box-goals.component.scss'],
    imports: [MatTable, MatColumnDef, MatCellDef, MatCell, NgIf, MatRowDef, MatRow],
})
export class OngoingMatchBoxGoalsComponent {
    public set: MatchSet;
    public setScoreGoalRole = SetScoreGoalRole;
    public goals: Array<IGoal>;

    private _match: IMatch;
    @Input() set match(value: IMatch) {
        this._match = value;
        this.setSet();
    }

    private _selectedSet: number;
    @Input() set selectedSet(value: number) {
        this._selectedSet = value;
        this.setSet();
    }

    private setSet() {
        const set = this._match?.sets[this._selectedSet];
        if (!set?.goals) {
            return;
        }
        const start = DateTime.fromISO(set.createdAt);
        this.goals = set.goals.map((goal, ix, self) => {
            const res: IGoal = {
                role: goal.role,
            };

            res.playerA =
                set.teamA.defense.id === goal.idPlayer
                    ? set.teamA.defense.displayName
                    : set.teamA.offense.id === goal.idPlayer
                      ? set.teamA.offense.displayName
                      : null;
            res.playerB =
                set.teamB.defense.id === goal.idPlayer
                    ? set.teamB.defense.displayName
                    : set.teamB.offense.id === goal.idPlayer
                      ? set.teamB.offense.displayName
                      : null;

            const scoreA = self.filter(
                (x, filterIx) => filterIx <= ix && (x.idPlayer === set.teamA.defense.id || x.idPlayer === set.teamA.offense.id),
            ).length;
            const scoreB = self.filter(
                (x, filterIx) => filterIx <= ix && (x.idPlayer === set.teamB.defense.id || x.idPlayer === set.teamB.offense.id),
            ).length;
            res.score = `${scoreA}:${scoreB}`;
            if (start) {
                const goalDate = DateTime.fromISO(goal.createdAt);
                const diff = goalDate.diff(start).rescale();
                if (diff.hours) {
                    res.time = diff.toFormat('h:mm:ss');
                } else {
                    res.time = diff.toFormat('m:ss');
                }
            }
            return res;
        });
    }
}
