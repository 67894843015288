import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, ElementRef, Input, OnInit, effect, inject, input, viewChild, viewChildren } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Session } from 'core/session.service';
import { SignalRService } from 'core/signalr.service';
import { GetMatchCommentEmojiModel, GetMatchCommentModel, GetMatchEmojiModel } from 'models';
import { filter } from 'rxjs/operators';
import { AppState, matchesActions } from 'store';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { UpsertCommentComponent } from '../upsert-comment/upsert-comment.component';
import { CommentAttachmentComponent } from '../comment-attachment/comment-attachment.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TranslateModule } from '@ngx-translate/core';
import { MomentPipe } from '../../pipes/moment.pipe';
import { PreserveNewlinePipe } from '../../pipes/preserve-newline.pipe';
import { AvatarUrlPipe } from '../../pipes/avatar-url.pipe';

interface IEmo {
    text: string;
    type: string;
    count?: number;
    players?: Array<number>;
    tooltip?: string;
}

@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
    imports: [
        NgIf,
        NgFor,
        MatTooltip,
        UpsertCommentComponent,
        CommentAttachmentComponent,
        NgClass,
        SweetAlert2Module,
        TranslateModule,
        MomentPipe,
        PreserveNewlinePipe,
        AvatarUrlPipe,
    ],
})
export class CommentComponent implements OnInit {
    #comment: GetMatchCommentModel;
    #destroyRef = inject(DestroyRef);

    #editKey = '';
    #replyKey = '';

    public emoChooserMouseOver = false;
    public hoveredEmo: IEmo = null;

    public highlight = false;

    public level = input<number>();

    public editMode = false;
    public showReply = false;

    private _childComments = viewChildren('childComment', { read: ElementRef });
    private _replyLine = viewChild('replyLine', { read: ElementRef });

    @Input() public set comment(value: GetMatchCommentModel) {
        this.updateEmojies(value.emojies);

        this.#comment = value;
        if (this.route.snapshot.queryParams.c) {
            this.highlight = +this.route.snapshot.queryParams.c === value.id;
        }
    }

    public get comment() {
        return this.#comment;
    }

    public emojies: Array<IEmo> = [
        { text: '👍', type: 'thumbs_up' },
        { text: '😆', type: 'laughing' },
        { text: '❤️', type: 'heart' },
        { text: '😢', type: 'sad_face' },
        { text: '😡', type: 'angry' },
    ];
    public showEmoChooser = false;
    public idPlayer: number;

    constructor(
        private readonly http: HttpClient,
        private readonly session: Session,
        private readonly signalR: SignalRService,
        private readonly route: ActivatedRoute,
        private readonly store: Store<AppState>,
    ) {
        this.idPlayer = session.user.idPlayer;

        effect(() => {
            const lastComment = this._childComments().at(-1);
            if (lastComment && this._replyLine()) {
                const height = (lastComment.nativeElement as HTMLElement).clientHeight;
                this._replyLine().nativeElement.style.height = `calc(100% - ${height + 30}px)`;
            }
        });
    }

    ngOnInit() {
        this.signalR.commentEmojiAdded$.pipe(filter((x) => this.#comment && this.#comment.id === x.commentId)).subscribe((res) => {
            this.updateEmojies(res.emojies);
        });

        this.#editKey = `${this.#comment.id || null}_${this.#comment.idMatch}_${this.#comment.idParent}`;
        this.#replyKey = `null_${this.#comment.idMatch}_${this.#comment.id}`;

        this.store
            .select((state) => state.matches.editingComments)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((editingComments) => {
                this.showReply = !!editingComments[this.#replyKey];
                this.editMode = !!editingComments[this.#editKey];
            });
    }

    private updateEmojies(emojies: Array<GetMatchCommentEmojiModel>) {
        this.emojies = this.emojies.map((e) => {
            const v = emojies.filter((x) => x.type === e.type);
            e.count = v.length;
            e.players = v.map((x) => x.idPlayer);
            e.tooltip = v.map((x) => x.playerDisplayName).join(', ');
            return e;
        });
    }

    public addCommentEmoji(idComment: number, type: string) {
        this.http
            .post<{ values: Array<GetMatchEmojiModel> }>(`/api/matches/${this.comment.idMatch}/comments/${idComment}/emoji`, {
                type: type,
                createdAt: new Date().toISOString(),
            })
            .subscribe((res) => {
                this.updateEmojies(res.values);
            });
    }

    public deleteComment(id: number) {
        this.http.delete(`/api/matches/${this.comment.idMatch}/comments/${id}`).subscribe();
    }

    public onEditCommentClick() {
        if (this.editMode) {
            this.store.dispatch(matchesActions.clearMatchComment({ key: this.#editKey }));
        } else {
            this.store.dispatch(
                matchesActions.updateMatchComment({
                    key: this.#editKey,
                    comment: {
                        text: this.#comment.text,
                        attachments: this.#comment.attachments,
                        imagePreview: this.#comment.imageUrl,
                        imageUrl: this.#comment.imageUrl,
                    },
                    skipIfExists: true,
                }),
            );
        }
    }
    public onReplyCommentClick() {
        if (this.showReply) {
            this.store.dispatch(matchesActions.clearMatchComment({ key: this.#replyKey }));
        } else {
            this.store.dispatch(
                matchesActions.updateMatchComment({
                    key: this.#replyKey,
                    comment: {
                        text: '',
                        attachments: [],
                        imagePreview: null,
                        imageUrl: null,
                    },
                    skipIfExists: true,
                }),
            );
        }
    }

    userReacted(emoji: IEmo) {
        return emoji.players.some((x) => x === this.session.user.idPlayer);
    }
}
