import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'preserveNewline' })
export class PreserveNewlinePipe implements PipeTransform {
    transform(value: unknown): unknown {
        if (value && typeof value === 'string') {
            const res = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
            return res;
        }
        return value;
    }
}
