import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss'],
    imports: [NgClass],
})
export class ChipComponent {
    @Input() public selected: boolean;
    @Output() public selectedChange = new EventEmitter<boolean>();
    @Input() public disabled = false;

    @Input() public id: string;
    @Input() public inverted = false;

    @Input() color: 'blue';
}
