import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GetMatchAchievementModel } from 'models';
import { NgFor, UpperCasePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TrophyPathPipe } from '../../pipes/trophy-path.pipe';

@Component({
    selector: 'app-match-box-achievements',
    templateUrl: './match-box-achievements.component.html',
    styleUrls: ['./match-box-achievements.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgFor, RouterLink, UpperCasePipe, TranslateModule, TrophyPathPipe],
})
export class MatchBoxAchievementsComponent {
    @Input() achievements: Array<GetMatchAchievementModel> = [];
}
