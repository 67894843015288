import { Component, input } from '@angular/core';
import { MatchInvitationCommentModel } from 'models';
import { NgIf } from '@angular/common';
import { AvatarUrlPipe } from '../../../pipes/avatar-url.pipe';

@Component({
    selector: 'app-match-invitation-comment',
    templateUrl: './match-invitation-comment.component.html',
    styleUrl: './match-invitation-comment.component.scss',
    imports: [NgIf, AvatarUrlPipe],
})
export class MatchInvitationCommentComponent {
    public comment = input.required<MatchInvitationCommentModel>();
}
