import { HttpClient } from '@angular/common/http';
import { Component, OnInit, VERSION } from '@angular/core';
import { AboutModel } from 'models';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { NgIf, AsyncPipe } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    imports: [
        MatDialogTitle,
        NgIf,
        CdkScrollable,
        MatDialogContent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        AsyncPipe,
        TranslateModule,
    ],
})
export class AboutComponent implements OnInit {
    public notes$: Observable<string>;
    public readonly version = environment.version;
    public about: AboutModel & { ngVersion: string };

    constructor(private readonly http: HttpClient) {}

    ngOnInit() {
        this.notes$ = this.http.get('/api/release-notes', {
            responseType: 'text',
            params: { appVersion: environment.version },
        });

        this.http.get<AboutModel>('/api/release-notes/about').subscribe((res) => (this.about = { ...res, ngVersion: VERSION.full }));
    }
}
