import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { IApiErrorInfo } from 'store/error/error.model';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-api-error-modal',
    templateUrl: './api-error-modal.component.html',
    styleUrls: ['./api-error-modal.component.scss'],
    imports: [CdkScrollable, MatDialogContent, MatDialogActions, MatButton, MatDialogClose, TranslateModule],
})
export class ApiErrorModalComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public error: IApiErrorInfo,
        private readonly dialogRef: MatDialogRef<ApiErrorModalComponent>,
    ) {}
    ngOnInit(): void {
        this.dialogRef.updateSize('550px');
    }
}
